.loading-container{
    height: auto;
    width: auto;
    margin: 0;
    padding: 0;
    box-shadow: none;
}

.loading{
    position: absolute;
    background-color: #ffffff;
    opacity: 0.9 !important;
    z-index: 100;
    width: 100%;
    height: 100%;
}

.loading-data {
    background-color: #ffffff;
    opacity: 0.9 !important;
    z-index: 100;
    width: 100%;
    height: 100%;
}

.loading img {
    height: 132px;
    width: 150px;
    margin: auto;
}
