@import "~animate.css/animate.min.css";
@import "~bootstrap/scss/bootstrap";
@import "./assets/scss/variables.scss";

@font-face {
    font-family: 'roboto light';
    src: url('./assets/fonts/Roboto/Roboto-Light.ttf');
}

@font-face {
    font-family: 'roboto medium';
    src: url('./assets/fonts/Roboto/Roboto-Medium.ttf');
}

@font-face {
    font-family: 'roboto bold';
    src: url('./assets/fonts/Roboto/Roboto-Bold.ttf');
}

@font-face {
    font-family: 'roboto regular';
    src: url('./assets/fonts/Roboto/Roboto-Regular.ttf');
}

.font-roboto-light {
    font-family: $font-roboto-light;
}

.font-roboto-bold {
    font-family: $font-roboto-bold;
}

.font-roboto-medium {
    font-family: $font-roboto-medium;
}
.font-roboto-regular {
    font-family: $font-roboto-regular;
}

.app-body {
    position: relative;
    min-height: 100vh;
    background-color: $lsc-color-light-gray;
}

.content-wrapper {
    padding-bottom: 254px;    /* Footer height */
}

body {
    font-family: $font-roboto-regular , sans-serif;
}
